import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types'

const defaultValues = {
}

const runtimeDefault = {
  element: null,
  styles: {},
  zIndex: 999,
  isCurrent: null,
  leftIndex: null,
  rightIndex: null,
}


const mixed = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
])

const isCurrent = (i, current) => {
  return i === current
}

const matchIndex = (index, slideIndex, total) => {
  return (index >= 0) ? slideIndex === index : (total + index) === slideIndex
}

const getSideIndex = (array = [], slideIndex, total) => {
  let index = -1
  array.forEach((pos, i) => {
    if (matchIndex(pos, slideIndex, total)) {
      index = i
    }
  })
  return index
}

const indexLocation = (leftIndices, rightIndices, slideIndex, total) => ({
  leftIndex: getSideIndex(leftIndices, slideIndex, total),
  rightIndex: getSideIndex(rightIndices, slideIndex, total),
})

const calculatePosition = (i, positive, zIndex, disable3d, inverseScaling, perspective, space, width) => {
  const z = !disable3d ? parseInt(inverseScaling) + ((i + 1) * 100) : 0
  const y = !disable3d ? parseInt(perspective) : 0
  const leftRemain = (space === 'auto')
      ? parseInt((i + 1) * (width / 1.5), 10)
      : parseInt((i + 1) * parseInt(space, 10), 10)

      const transform = (positive)
      ? 'translateX(' + (leftRemain) + 'px) translateZ(-' + z + 'px) ' +
      'rotateY(-' + y + 'deg)'
      : 'translateX(-' + (leftRemain) + 'px) translateZ(-' + z + 'px) ' +
      'rotateY(' + y + 'deg)'
  const top = space === 'auto' ? 0 : parseInt((i + 1) * parseInt(space, 10))

  return {
      transform: transform,
      // top: top,
      zIndex: zIndex - (Math.abs(i) + 1)
  }
}

const slideStyle = (
  { runtime,
  hasHiddenSlides,
  leftOutIndex,
  rightOutIndex,
  leftIndices,
  rightIndices,
  border,
  borderColor,
  boxShadow,
  slideWidth,
  slideHeight,
  animationSpeed,
  disable3d,
  inverseScaling,
  perspective,
  space,
  backgroundColor,
  width }
  ) => {
  let styles = {}

  if (!runtime.isCurrent) {
    const lIndex = runtime.leftIndex
    const rIndex = runtime.rightIndex
    if (rIndex >= 0 || lIndex >= 0) {
      styles = rIndex >= 0 ? 
        calculatePosition(
          rIndex, 
          true, 
          runtime.zIndex,
          disable3d,
          inverseScaling,
          perspective,
          space,
        width) : 
        calculatePosition(
          lIndex,
          false,
          runtime.zIndex,
          disable3d,
          inverseScaling,
          perspective,
          space,
        width)
      styles.opacity = 1
      styles.visibility = 'visible'
    }

    if (hasHiddenSlides) {
      if (matchIndex(leftOutIndex)) {
        styles = calculatePosition(
          leftIndices.length - 1,
          false,
          runtime.zIndex,
          disable3d,
          inverseScaling,
          perspective,
          space,
        )
      } else if (matchIndex(rightOutIndex)) {
        styles = calculatePosition(
          rightIndices.length - 1,
          true,
          runtime.zIndex,
          disable3d,
          inverseScaling,
          perspective,
          space,
        )
      }
    }
  }

  return Object.assign(styles, {
      borderWidth: `${border}px`,
      borderColor,
      boxShadow,
      width: `${slideWidth}px`,
      height: `${slideHeight}px`,
      transition: `transform ${animationSpeed}ms, opacity ${animationSpeed}ms, visibility ${animationSpeed}ms`,
      backgroundColor,
  })
}


const Slide = (receivedProps) => {
  const props = useMemo(() => ({ ...defaultValues, ...receivedProps }), [receivedProps])
  const [runtime, setRuntime] = useState(runtimeDefault)
  const { classes } = props;
  const slideEl = useRef(null)

  const runtimeEval = (index, current, total, leftIndices, rightIndices) => ({
    ...runtimeDefault,
    ...indexLocation(leftIndices, rightIndices, index, total),
    isCurrent: isCurrent(index, current),
  }) 

  const computeClasses = (classes, runtime) => {
    let named = ''
    if (runtime.leftIndex >= 0) named = `left-${runtime.leftIndex + 1}`
    if (runtime.rightIndex >= 0) named = `right-${runtime.rightIndex + 1}`
    if (runtime.isCurrent) named = classes.current
    return named
  }
  
  const goTo = () => {
    if (!runtime.isCurrent) {
      if (props.clickable === true) {
        props.goFar(props.index)
      }
    } else {
      props.onMainSlideClick()
    }
  }

  const computed = useMemo(() => {
    const runtime = runtimeEval(props.index, props.currentIndex, props.total, props.leftIndices, props.rightIndices)
    setRuntime((next) => ({
      ...next,
      ...runtime,
    }))
    const styles = slideStyle(
      { runtime,
      ...props }
    )
    const classNames = computeClasses(classes, runtime)
    return {
      runtime,
      classes,
      classNames,
      styles,
    }
  }, [
    props,
    classes,
  ])

  return (
    <div 
      ref={slideEl}
      className={`${classes.slide}
      ${computed.classNames}`}
      key={props.index}
      style={computed.styles}
      onClick={goTo}
      role="button"
      onKeyPress={props.onKeyPressHandler}
      tabIndex="-1"
    >
      {props.children}
    </div>
  )
}

Slide.propTypes = {
  index: PropTypes.number,
  currentIndex: PropTypes.number,
  leftIndices: PropTypes.array,
  rightIndices: PropTypes.array,
  total: PropTypes.number,
  hasHiddenSlides: PropTypes.bool,
  leftOutIndex: PropTypes.number,
  border: mixed,
  animationSpeed: mixed,
  disable3d: PropTypes.bool,
  inverseScaling: mixed,
  perspective: mixed,
  space: mixed,
  width: mixed,
  clickable: PropTypes.bool,
  goFar: PropTypes.func,
  onMainSlideClick: PropTypes.func,
}

export default Slide
