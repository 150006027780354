import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { updatedDaysAgo } from '../../hooks/useGamesForSaleState';
import MiniGamesDisplay from '../MiniGameDisplay';

const gridWidth = {
  xs: 4,
  sm: 3,
  md: 3,
  lg: 2,
  xl: 1,
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
}));

const DEFAULT_DAYS = 7;

const UpdatedGames = (props) => {
  const { days } = props
  const liveGamesFilter = game => {
    const updated = updatedDaysAgo(days || DEFAULT_DAYS)(game);
    return (updated && game.data.inStock === true && parseInt(game.data.units, 10) >= 1)
  };
  const allGamesFilter = (filteredGames) => {
    const filteredIds = filteredGames.map((g) => g.id);
    return (game) => {
      return filteredIds.includes(game.game_for_sale[0].id)
    }
  }
  return (
    <MiniGamesDisplay allGamesFilter={allGamesFilter} liveGamesFilter={liveGamesFilter} />
  );
}

export default UpdatedGames;