import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '../../CardActionArea';
import { makeStyles } from '@material-ui/core/styles';

import TouchAppIcon from '@material-ui/icons/TouchApp';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
  touch: {
    position: 'absolute', 
    right: 20,
    top: 20,
  },
  avatar: {
    color: theme.palette.primary.main,
    backgroundColor: '#00000010',
  }
}))

const getCity = (game, preferred = 'ba') => {
  const city = game.cities.find((c) => c === preferred)
  return city && city.contentful_id || preferred
}

const BoardGameImageCard = (props) => {
  const { slide, carousel } = props;
  const classes = useStyles();

  const game = slide.entity;
  const city = getCity(game, 'ba');
  const gameOrExpansion = (game.expansion) ? 'expansion' : 'juego';
 
  return (
    <>
    <Card>
      {/* <CardActionArea to={`/${city}/${gameOrExpansion}/${game.contentful_id}/${game.slug}/`}> */}
      <CardActionArea to={`/ba/${gameOrExpansion}/${game.contentful_id}/${game.slug}/`}>
        <CardHeader
          title={game.title}
          subheader={`juegan: ${game.minimumPlayers} - ${game.maximumPlayers} | Duración: ${game.minimumPlayingTime} : ${game.maximumPlayingTime}`}
        />
                
        <div className={classes.touch}><Avatar className={classes.avatar}><TouchAppIcon fontSize="large"></TouchAppIcon></Avatar></div>

      </CardActionArea>
      <GatsbyImage
        alt={game.title || 'Boardgame'}        
        style={{ margin: 0, height: carousel.height, width: carousel.width }}
        image={game.image.gatsbyImageData}
      />

    </Card>
    
    </>
  )

}

export default BoardGameImageCard;
