import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import MiniCard from '../MiniCard';
import { gridStyles } from '../GameControlNavigator';
import useGamesForSaleState from '../../hooks/useGamesForSaleState';
import useGamesIndex from '../../hooks/useGamesIndex';

const gridWidth = {
  xs: 4,
  sm: 3,
  md: 3,
  lg: 2,
  xl: 1,
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
}));


const MiniGamesDisplay = (props) => {
  const classes = gridStyles();
  const [allGames, setAllGames] = useState();
  const [games, setGames] = useState();
  const { liveGamesFilter, allGamesFilter } = props;
  useGamesIndex({ games: allGames, setGames: setAllGames, filename: 'forSale-new.json'});
  const { gamesForSale: liveGames, gameForSaleInfo, liveInfoLoaded, jsonLd } = useGamesForSaleState();
  useEffect(() => {
    if (liveInfoLoaded && allGames && !games) {
      const filtered = liveGames.filter(liveGamesFilter);
      const filteredGames = allGames.filter(allGamesFilter(filtered));
      setGames(filteredGames);
    }
  }, [games, allGames, liveInfoLoaded, liveGames, allGamesFilter, liveGamesFilter])
  return (
    <Grid container spacing={1} id={`games-start-0`} className={classes.root}>
    {games && games.map((game) => {
      return (
      <Grid key={game.id} item xl={gridWidth.xl} >
        <MiniCard classes={classes} game={game} city="ba" gameForSaleInfo={gameForSaleInfo} liveInfoLoaded={liveInfoLoaded} />
      </Grid>
    )})}
    </Grid>

  );
}

export default MiniGamesDisplay;