import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import documentToReact from '../../lib/contentful/documentToReact';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Hero from '../../components/Hero';
import { GatsbyImage } from "gatsby-plugin-image"

import { richTextParser } from '../util'

const useStyles = (props) => 
  makeStyles(theme=>({
    container: {
      position: 'relative',
    },
    overlay: {
      top: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      height: '100%',
      width: '100%',
    },
    title: {
      paddingTop: '2rem',
    }
  }));

const gridClasses = makeStyles(theme=>( {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    height: '100%',
    width: '100%',
  },
  oneItem: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexBasis: '100%',
  },
  twoItems: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexBasis: '50%',
  },
  threeItems: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexBasis: '33%',
  },
  item: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
  }
}))

const GridModel = (props) => {
  const { isMobile, gridModel, gridPosition, textPosition, portraitGridModel, portraitGridPosition} = props
  const classes = gridClasses()
  let rowClass
  let columnClass
  let rows = 1
  let columns = 1
  let contentStyle

  if (isMobile) {
    switch (portraitGridModel) {
      case 3:
        rowClass = 'threeItems'
        columnClass = 'oneItem'
        rows = 3
        columns = 1
        break
      case 2:
        rowClass = 'twoItems'
        columnClass = 'oneItem'
        rows = 2
        columns = 1
        break
      case 1:
        rowClass = 'oneItem'
        columnClass = 'oneItem'
        rows = 1
        columns = 1
        break
      default:
        rowClass = 'oneItem'
        columnClass = 'oneItem'
        rows = 1
        columns = 1
        break
    }
  } else {
    switch (gridModel) {
      case 2:
        rowClass = 'oneItem'
        columnClass = 'twoItems'
        rows = 1
        columns = 2
        
        break;
      case 3:
        rowClass = 'oneItem'
        columnClass = 'threeItems'
        rows = 1
        columns = 3
        
        break;
      case 4:
        rowClass = 'twoItems'
        columnClass = 'twoItems'
        rows = 2
        columns = 2
        break;
      case 6:
        rowClass = 'twoItems'
        columnClass = 'threeItems'
        rows = 2
        columns = 3
        break;
      case 9:
        rowClass = 'threeItems'
        columnClass = 'threeItems'
        rows = 3
        columns = 3
        break;
      default:
        rowClass = 'oneItem'
        rows = 1
        columns = 1
    }  
  }


  switch (textPosition) {
    case 1:
      contentStyle = { justifyContent: 'flex-start', alignItems: 'flex-start' }
      break
    case 2:
      contentStyle = { justifyContent: 'flex-start' }
      break
    case 3:
      contentStyle = { justifyContent: 'flex-start' }
      break
    case 4:
      contentStyle = { justifyContent: 'center', flexDirection: 'column' }
      break
    case 5:
      contentStyle = { justifyContent: 'flex-start', alignItems: 'center' }
      break
    case 6:
      contentStyle = { justifyContent: 'flex-start' }
      break
    case 7:
      contentStyle = { justifyContent: 'flex-start' }
      break
    case 8:
      contentStyle = { justifyContent: 'flex-start', alignItems: 'flex-end' }
      break
    case 9:
      contentStyle = { justifyContent: 'flex-end' }
      break
  }

  const r = Array(rows).fill('r')
  const c = Array(columns).fill('c')
  const position = (isMobile) ? portraitGridPosition : gridPosition

  return (
    <div className={classes.container}>
    {r.map((a, i) => {
      return (
        <div key={`${i}`} className={classes[rowClass]} style={{flexDirection: 'row', flexBasis: '100%'}}>
          {c.map((b, j) => {
            const display = ((((i) * (c.length)) + j) === position - 1)
            return (
              <div key={`${i}${j}`} className={classes[columnClass]}>
                <div style={{...contentStyle, display: 'flex', padding: '2rem'}}>
                  {display && props.children}
                </div>
              </div>
            )
          })}
        </div>
      )
    })}
    </div>
  )
}

const ComponentHero = (props) => {
  const { matchedQuery } = props
  const classes = useStyles(props)();
  const id = props.componentId
  const data = props.data[id]
  if (!data.image) {
    // console.log('ComponentHero data', data)
  }
  const isMobile = (matchedQuery === 'xs' || matchedQuery === 'sm' || matchedQuery === 'md')
  const codeHero = data.codeHero
  const image = !codeHero && data.image.gatsbyImageData
  const portraitImage = !codeHero && (data.portraitImage || data.image).gatsbyImageData ;
  // const image = !codeHero && data.image.fluid
  // const portraitImage = !codeHero && (data.portraitImage || data.image).fluid ;
  const hasText = Boolean(data && data.overlayText && data.overlayText.raw);
  const title = data && data.title && data.title !== '' && data.title

  return (
    <>
    {codeHero && <Hero />}
    {!codeHero && 
      <>
      {title && title !== '' &&
      <div className={classes.title}>
        <Typography variant="h3" component="h1" align="center">{title}</Typography>
      </div>
      }
      <div className={classes.container}>
        <Hidden only={['xl', 'lg']}>
          <GatsbyImage image={portraitImage} alt={image.title || 'image'} /> 
        </Hidden>
        <Hidden mdDown>
          <GatsbyImage image={image} alt={image.title || 'image'} /> 
        </Hidden>
      {hasText && <div className={classes.overlay}>
        <GridModel
          matchedQuery={matchedQuery}
          gridModel={data.gridModel}
          gridPosition={data.gridPosition}
          portraitGridModel={data.portraitGridModel}
          portraitGridPosition={data.portraitGridPosition}
          textPosition={data.textPosition}
          isMobile={isMobile}
        >
          {documentToReact(richTextParser(data.overlayText.raw), {style: { color: data.textColor }})}
        </GridModel>
      </div>}
    </div>
    </>
    }
    </>
  )
}

export default ComponentHero;