import React, { useState, useCallback, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import useAnimationFrame from '../../hooks/useAnimationFrame';
import Item from './Item';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    fontSize: '40px',
    display: 'block',
    color: '#fff',
    width: '100%',
  }
}));

const counterInit = (data) => ({
  start: 0,
  value: 0,
  end: data.total,
  ended: false,
  delay: 2000 / data.total,
  label: data.label,
  visible: false,
  backgroundColor: data.backgroundColor,
  url: data.url,
})


const stateSpread = counters => 
  counters && counters.map(counter => counterInit(counter))

const Counter = props => {

  const { starters } = props
  const classes = useStyles();
  const [counters, setCounters] = useState(stateSpread(starters))
  const activeCounter = useRef(0)
  const allVisible = useRef(false)
  const animate = (steps) => {
    setCounters((prev) => {
      const totalSecs = 3
      if (!prev) return prev
      const next = prev.map((counter, index) => {
        if (
          (allVisible.current && index === activeCounter.current) || (!allVisible.current && counter.visible)) {
          if (!counter.ended && counter.value === counter.end) {
            counter.ended = true
          } else {
            const totalFrames = totalSecs * steps
            const length = counter.end
            const step = length / totalFrames
            counter.value = Math.min(counter.value + step, counter.end)
            if (!counter.ended && counter.end === counter.value) {
              counter.ended = true
            }
          }
          return counter;  
        } else {
          return counter;
        }
      });
      return next;
    })
  }
  useEffect(() => {
    if (counters && counters[activeCounter.current].ended) {
      activeCounter.current = (activeCounter.current < counters.length - 1) ? activeCounter.current + 1 : activeCounter.current
    }
  }, [counters])
  const setVisible = useCallback((index) => (val) => setCounters((prev) => {
    prev[index] = {
      ...prev[index],
      visible: val,
    }
    allVisible.current = prev.every(p => p.visible)
    return prev
  }), [setCounters])


  useAnimationFrame((deltaTime) => {
    const delta = deltaTime / 1000
    const steps = 1 / delta
    animate(steps)
  })

  return (
    <Grid container className={classes.content} spacing={1}>
      {counters && counters.map((counter, index) => (
        <Item
          key={`counter${index}`}
          counter={counter}
          index={index}
          setVisible={setVisible}
          backgroundColor={counter.backgroundColor}
        />
      ))}
    </Grid>
  )
}


export default Counter;