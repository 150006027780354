import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Carousel from '../../components/Carousel'
import Slide from '../../components/Carousel/Slide'
import Typography from '@material-ui/core/Typography'
import RenderCard from './RenderCard'

const useStyles = makeStyles((theme) => ({
    h1: {
        fontSize: '3.125rem',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: -1,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.875rem',
        },
    },
    h2: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.125rem',
        },
    },
    body1: {
        fontFamily: 'IBM Plex Sans',
        fontSize: '1.125rem',
        fontWeight: 400,
        lineHeight: 1.6,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    body2: {
        fontFamily: 'IBM Plex Sans',
        fontSize: '1.125rem',
        fontWeight: 400,
        lineHeight: 1.6,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
}))

export default (props) => {
    const data = props.data[props.componentId]
    const { slides, ...sliderProps } = data
    const classes = useStyles()
    const hasTitle = Boolean(data.title)

    return (
        <Box pt={5} textAlign="center">
            {hasTitle && (
                <Typography className={classes.h1} gutterBottom>
                    {data.title}
                </Typography>
            )}
            <Carousel
                {...sliderProps}
                display={data.displayableSlides}
                controlsVisible={data.controls}
                space={240}
            >
                {slides.map((slide) => {
                    return (
                        <Slide key={slide.contentful_id} classes={classes} height={data.height} width={data.width}>
                          <RenderCard slide={slide} carousel={data} />
                        </Slide>
                    )
                })}
            </Carousel>
        </Box>
    )
}
