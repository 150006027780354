import React from 'react';
import ComponentBlock from '../../components/ComponentBlock';

export default (props) => {
  const data = props.data[props.componentId];
  const image = (data && data.image && data.image.gatsbyImageData);
  const title = (data && data.title);
  const direction = (data && data.direction || 'row');
  const textAlign = (direction === 'row') ? 'left' : 'right';
  const cta = (data && data.cta && data.cta.link) ? data.cta : null;
  // const slideDirection = (direction === 'row') ? { direction: 'right' } : { direction: 'left' };
  const absoluteLink = Boolean(cta && cta.link && cta.link.startsWith('https://'))
  const name = data.name
  // href={cta.link}
  // iphone 6 10.3.1: Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E8301 Safari/602.1
  // iphone 5 10.3.1: Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E8301 Safari/602.1
  // iphone 6 12.0  : Mozilla/5.0 (iPhone; CPU iPhone OS 12_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.0 Mobile/15E148 Safari/604.1
  // iphone x 12.0  : Mozilla/5.0 (iPhone; CPU iPhone OS 12_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.0 Mobile/15E148 Safari/604.1
  // iphone 6 14.2  : Mozilla/5.0 (iPhone; CPU iPhone OS 14_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0.1 Mobile/15E148 Safari/604.1
  return (
    <ComponentBlock
      image={image}
      title={title}
      direction={direction}
      textAlign={textAlign}
      cta={cta}
      absoluteLink={absoluteLink}
      name={name}
      content={data.content}
    />
    // <div style={{overflowX: 'hidden', contentVisibility: 'auto'}}>
      
    //   <Box m={3}>
    //   <Grid container spacing={3} direction={direction} alignItems="center" justify="center">
    //     <Grid item lg={4} xl={4} md={3} sm={6} xs={12}>
    //       {image && <GatsbyImage image={image} alt={title}/>}
    //     </Grid>
    //     <Grid item lg={8} xl={8} md={9} sm={6} xs={12}>
    //       <Grid container direction="column">
    //         <Grid item>
    //           {title && <Typography variant="h3" component="h2" gutterBottom align={textAlign}>{title}</Typography>}
    //           <Typography variant="body1" component="div" align={textAlign}>
    //             {documentToReact(richTextParser(data.content.raw))}
    //           </Typography>
    //         </Grid>
    //         <Grid item>
    //           {cta && !absoluteLink &&
    //               <Button onClick={handleClick()} variant="contained" color={cta.color}>{cta.label}</Button>
    //           }
    //           {cta && absoluteLink &&
    //             <Button to={cta.link} target="blank" variant="contained" color={cta.color}>{cta.label}</Button>
    //           }
    //           </Grid>
    //       </Grid>
    //     </Grid>
    //   </Grid>
    //   </Box>
    // </div>

  )
}

/*

              {cta && 
                <FirebaseContext.Consumer>
                  {firebase => {
                    return (
                      <Button onClick={handleClick(firebase)} variant="contained" color={cta.color}>{cta.label}</Button>
                    )
                  }
                }
                </FirebaseContext.Consumer>}

*/