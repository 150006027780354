import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Counter from '../Counter';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(7),
  },
  shopLink: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  girl: {
    maxWidth: 100,
    [theme.breakpoints.up('xs')]: {
      maxWidth: 100,
      minWidth: 100,
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 120,
      minWidth: 120,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 150,
      maxWidth: 150,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 180,
      minWidth: 180,
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 200,
      maxWidth: 200,
    },
  },
  brand: {
    textAlign: 'center',
  },
  shop: {
    textAlign: 'center',
  },
  brandImg: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#00B8D4',
    },
    margin: 'auto',
    maxWidth: 150,
    width: 150,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(3),
    [theme.breakpoints.up('xs')]: {
      minHeight: 67,
      width: 150,
      maxWidth: 150,
      minWidth: 150,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 67,
      width: 150,
      maxWidth: 150,
      minWidth: 150,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 67,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
    },
    [theme.breakpoints.up('lg')]: {
      width: 250,
      maxWidth: 250,
      minWidth: 250,
    },
    [theme.breakpoints.up('xl')]: {
      width: 300,
      minWidth: 300,
      maxWidth: 300,
    },
  },
  shopImg: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F6497F',
    },
    padding: theme.spacing(3),
    borderRadius: theme.spacing(3),
    margin: 'auto',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 150,
      minWidth: 150,
      width: 150,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 200,
      maxWidth: 200,
      width: 200,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 220,
      minWidth: 220,
      width: 220,
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 250,
      maxWidth: 250,
      width: 250,

    },
  },
  
}));

const Hero = (props) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
  query hero {
    shop: contentfulAsset(node_locale: {eq: "es-AR"}, title: {eq: "Shop logo svg"}) {
          id
          title
          file {
            url
          }
    }
    brand: contentfulAsset(node_locale: {eq: "es-AR"}, title: {eq: "Reservar Home svg"}) {
          id
          title
          gatsbyImageData(
            layout: CONSTRAINED,
            placeholder: DOMINANT_COLOR,
          )    
          file {
            url
          }
        }
    skate: contentfulAsset(node_locale: {eq: "es-AR"}, title: {eq: "lp skate girl ttr"}) {
      gatsbyImageData(
        layout: FULL_WIDTH,
        placeholder: BLURRED,
      )    
    }
  }
  
  `)
  return(
    <React.Fragment>
    <Grid container alignItems="center" direction="row" justify="center" className={classes.container}>
      <Grid lg={4} md={4} xs={12} sm={4} item className={classes.brand}>
        <Link to="/app/evento/" className={classes.shopLink}>
          <div className={classes.brandImg}>
          <GatsbyImage image={data.brand.gatsbyImageData} alt="reservar" layout="constrained" />

          </div>
        </Link>
      </Grid>
      <Grid lg={4} md={4} xs={12} sm={4} item className={classes.girl}>
        <GatsbyImage image={data.skate.gatsbyImageData} alt="persona ticket to ride" />

      </Grid>
      <Grid lg={4} md={4} xs={12} sm={4} item className={classes.shop}>
        <Link to="/shop/home" className={classes.shopLink}>
          <img className={classes.shopImg}  src={data.shop.file.url} alt="Shop Button" loading="eager" />
        </Link>
      </Grid>
    </Grid>
    <Counter />
    </React.Fragment>

  )
}

export default Hero;