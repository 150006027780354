import { useEffect, useState } from 'react';
import getJson from '../lib/network/getJson';

const useGamesIndex = (props) => {
  const { filename, games, setGames } = props;
  const path = (process.env.NODE_ENV === 'production') ? `https://storage.googleapis.com/pamir-public-db/index/` : `/data/index/`

  useEffect(() => {
    const fetchHandler = () => {
      return getJson(`${path}${filename}`).then((json) => {
        setGames(json.items);
      });
    };
    if (!games) {
      fetchHandler();
    }
  }, [filename, games, setGames, path]);
}

export default useGamesIndex;