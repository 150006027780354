import React, { useState, useEffect } from 'react';
const dynamicParser = (data) => {
  return JSON.parse(data.dynamicComponent.dynamicComponent)
}

const getDynamicData = (data, parsedData) => {
  if (parsedData.component === "MultipleCounter") {
    return Object.keys(data).reduce((accum, key) => {
      if (key.startsWith('dynamicComonentQueryGamesCounter')) {
        const updated = {
          ...accum,
          ...data[key],
        }
        return updated
      }
      return accum
    }, {})  
  } else {
    return Object.keys(data).reduce((accum, key) => {
      if (key.endsWith(parsedData.fields.name)) {
        const updated = {
          ...accum,
          ...data[key],
        }
        return updated
      }
      return accum
    }, {})  
  }
}

export default (props) => {
  const data = props.data[props.componentId];
  const [ LazyComponent, setLazyComponent ] = useState(null)

  useEffect(() => {
    const loadLazyComponent = async () => {  
      const { default: lazyModule} = await import(`../../dynamicComponents/${data.component}`)
      const parsedData = dynamicParser(data)
      const dynamicData = getDynamicData(props.data, parsedData)
      const componentProps = {
        // pass props to component here
        dynamicData,
        data,
        parsedData,
        name
      }
      const LazyElement = React.createElement(lazyModule, componentProps)
      setLazyComponent(LazyElement)
    }
    if (data && data.component && LazyComponent === null) {
      loadLazyComponent()
    }
    return () => setLazyComponent(null)
  }, [])
  return (
    <>
      {LazyComponent}
    </>
  )
}
