import React from 'react';
import { Helmet } from 'react-helmet';
import { getSrc } from "gatsby-plugin-image"

const ComponentSeo = (props) => {
  const {siteUrl} = props.data.site.siteMetadata
  const id = props.componentId
  const data = props.data[id]
  const { pathName } = props
  const { title, description, image, type } = data;
  const path = (pathName) ? `${pathName}` : '';
  const canonical = pathName ? `${siteUrl}${pathName}` : null
  const gatsbyImage = getSrc(image);
  const seo = {
    title: title,
    description: (description && description.description) || description || '',
    image: gatsbyImage,
    siteUrl,
    url: `${siteUrl}${path}/`,
    type,
  };

  return (
    <>
      <Helmet
        title={seo.title}
        titleTemplate={seo.title}
        defer={false}
        link={
          canonical
            ? [
                {
                  rel: "canonical",
                  href: seo.url,
                },
              ]
            : []
        }
        >
        <link rel="dns-prefetch" href="//www.google-analytics.com" crossOrigin />
        <link rel="dns-prefetch" href="//images.ctfassets.net" crossOrigin />
        <meta name="description" content={seo.description} />
        <meta name="image" content={`https:${seo.image}`} />
        {seo.url && <meta property="og:url" content={seo.url || 'https://www.conexionberlin.com'} />}
        {seo.type && (
          <meta property="og:type" content={seo.type} />
        )}
        {seo.title && (
          <meta property="og:title" content={seo.title} />
        )}
        {seo.description && (
          <meta
            property="og:description"
            content={seo.description}
          />
        )}
        {seo.image && (
          <meta property="og:image" content={`${seo.image}`} />
        )}
      </Helmet>

    </>
  )
}

export default ComponentSeo;