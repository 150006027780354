import React, { useState, useRef, useLayoutEffect, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import Controls from './Controls'
import useInterval from '../../hooks/useInterval'

const noop = () => {}

const isBrowser = typeof window !== 'undefined';

const useStyles = makeStyles(theme => ({
  container: {
		minHeight: 1,
		width: '100%',
		position: 'relative',
		zIndex: 0,
		overflow: 'hidden',
		margin: '20px auto',
		boxSizing: 'border-box',
	},
	carouselSlider: {
		position: 'relative',
		margin: '0 auto',
    transformStyle: 'preserve-3d',
    WebkitPerspective: '1000px',
    MoxPerspective: '1000px',
    perspective: '1000px',
	},
  slide: {
		position: 'absolute',
		opacity: 0,
		visibility: 'hidden',
		overflow: 'hidden',
		top: 0,
		borderRadius: 2,
		borderColor: 'rgba(0, 0, 0, 0.4)',
		borderStyle: 'solid',
		backgroundSize: 'cover',
		backgroundColor: 'transparent',
		display: 'block',
		margin: 0,
		boxSizing: 'border-box',
		textAlign: 'left',
    boxShadow: 'none', //'5px 5px 9px 0px rgba(0, 0, 0, 0.44)',
	},

	slideImg: {
		width: '100%',
	},

	current: {
		opacity: '1 !important',
		visibility: 'visible !important',
		transform: 'none !important',
		zIndex: 999,
	}
}));

const defaultValues = {
  count: 0,
  perspective: 35,
  display: 5,
  loop: true,
  animationSpeed: 500,
  dir: 'rtl',
  width: 360,
  height: 270,
  border: 1,
  borderColor: '#ccc',
  space: 'auto',
  startIndex: 0,
  clickable: true,
  disable3d: false,
  minSwipeDistance: 10,
  inverseScaling: 300,
  controlsVisible: false,
  controlsPrevHtml: '&lsaquo;',
  controlsNextHtml: '&rsaquo;',
  controlsWidth: 50,
  controlsHeight: 50,
  onLastSlide: noop,
  onSlideChange: noop,
  bias: 'right',
  onMainSlideClick: noop,
  beforeSlideChange: noop,
  // autoplay mixin
  autoplay: false,
  autoplayTimeout: 2000,
  autoplayHoverPause: true,
}

const mixed = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
])

const runtimeDefault = {
  viewport: 0,
  currentIndex: 0,
  total: 0,
  dragOffset: 0,
  dragStartX: 0,
  mousedown: false,
  zIndex: 998,
  // autoplay mixin
  autoplayInterval: null,
}

/*
  Methods
*/

const getBoxShadowSize = (boxShadow = '') => {
  const parts = boxShadow.split(' ')
  if (parts.length === 1) return 0
  const numbers = parts.slice(0,4).map(part => parseInt(part.replace('px', ''), 10))
  return Math.max(...numbers.slice(0,2)) + numbers[2]
}

export const getSlideWidth = (viewport, width, border, boxShadow) => {
  const sw = parseInt(width, 10) + ((parseInt(border, 10)) * 2)

  return viewport < sw && process.browser ? viewport : sw
}

export const getSlideHeight = (viewport, width, height, border, boxShadow) => {
  const sw = parseInt(width, 10) + ((parseInt(border, 10)) * 2)
  const sh = parseInt(height, 10) + ((parseInt(border, 10)) * 2)
  const ar = calculateAspectRatio(sw, sh)

  return getSlideWidth(viewport, width, border, boxShadow) / ar
}

const leftIndices = (visible, bias, dir, currentIndex, total) => {
  let n = (visible - 1) / 2

  n = (bias.toLowerCase() === 'left' ? Math.ceil(n) : Math.floor(n))

  const indices = []

  for (let m = 1; m <= n; m++) {
    indices.push((dir === 'ltr')
      ? (currentIndex + m) % (total)
      : (currentIndex - m) % (total))
  }
  return indices
}

const rightIndices = (visible, bias, dir, currentIndex, total) => {
  let n = (visible - 1) / 2

  n = (bias.toLowerCase() === 'right' ? Math.ceil(n) : Math.floor(n))
  const indices = []

  for (let m = 1; m <= n; m++) {
    indices.push((dir === 'ltr')
      ? (currentIndex - m) % (total)
      : (currentIndex + m) % (total))
  }

  return indices
}



/**
  * Calculate slide with and keep defined aspect ratio
  * @return {Number} Aspect ratio number
*/
const calculateAspectRatio = (width, height) => {
  return Math.min(width / height)
}

const Carousel = (receivedProps) => {
  const props = { ...defaultValues, ...receivedProps }
  const [runtime, setRuntime] = useState(runtimeDefault)
  const carouselEl = useRef(null);
  const slotsEl = useRef(null);
  const classes = useStyles();
  const [isAutoplayRunning, setIsAutoplayRunning] = useState(props.autoplay);

  // executed immediately
  const total = props.children.length
  const isLastSlide = runtime.currentIndex === total - 1
  const isFirstSlide = runtime.currentIndex === 0
  const visible = (props.display > total) ? total : props.display
  const hasHiddenSlides = total > visible

  const onKeyPressHandler = useCallback(() => {
    return true
  },[])

  /**
   * Go to slide
   * @param  {String} index of slide where to go
   */
  const goSlide = useCallback((index, beforeSlideChange) => {
    const nextCurrentIndex = (index < 0 || index > total - 1) ? 0 : index
    setRuntime((next) => {
      beforeSlideChange(next.currentIndex)
      return ({
        ...next,
        currentIndex: nextCurrentIndex,
      })
    })
  }, [total])


    /**
   * Go to next slide
   */
  const goNext = useCallback(() => {
    if (runtime.isNextPossible) {
      isLastSlide ? goSlide(0, props.beforeSlideChange) : goSlide(runtime.currentIndex + 1, props.beforeSlideChange)
    }
  }, [runtime.isNextPossible, goSlide, isLastSlide, runtime.currentIndex, props.beforeSlideChange])

  /**
   * Go to previous slide
   */
  const goPrev = useCallback(() => {
    if (runtime.isPrevPossible) {
      isFirstSlide ? goSlide(total - 1, props.beforeSlideChange) : goSlide(runtime.currentIndex - 1, props.beforeSlideChange)
    }
  }, [total, goSlide, isFirstSlide, runtime.isPrevPossible, runtime.currentIndex, props.beforeSlideChange])

  /**
   * Go to slide far slide
   */
  const goFar = useCallback((index) => {
    let diff = (index === total - 1 && isFirstSlide) ? -1 : (index - runtime.currentIndex)

    if (isLastSlide && index === 0) {
        diff = 1
    }

    const diff2 = (diff < 0) ? -diff : diff
    let timeBuff = 0
    let i = 0

    while (i < diff2) {
        i += 1
        const timeout = (diff2 === 1) ? 0 : (timeBuff)

        setTimeout(() => (diff < 0) ? goPrev(diff2) : goNext(diff2), timeout)

        timeBuff += (props.animationSpeed / (diff2))
    }
  }, [total, isFirstSlide, isLastSlide, runtime.currentIndex, goPrev, goNext, props.animationSpeed])

  const callback = (props.dir === 'ltr') ? goPrev : goNext
  const delay = (props.autoplay && isAutoplayRunning) ? props.autoplayTimeout : null
  useInterval(callback, delay)

  const pauseAutoplay = () => setIsAutoplayRunning(false)
  const resumeAutoplay = () => setIsAutoplayRunning(true)

  useLayoutEffect(() => {
    const total = props.children.length
    const isLastSlide = runtime.currentIndex === total - 1
    const isFirstSlide = runtime.currentIndex === 0
    const visible = (props.display > total) ? total : props.display
    const slideWidth = getSlideWidth(props.viewport, props.width, props.border, props.boxShadow)
    const slideHeight = getSlideHeight(props.viewport, props.width, props.height, props.border, props.boxShadow)
    const theElement = carouselEl.current

    setRuntime((next) => ({
      ...next,
      total,
      viewport: theElement.clientWidth,
      slideWidth,
      slideHeight,
      isLastSlide,
      isFirstSlide,
      visible,
      isNextPossible: !(!props.loop && isLastSlide),
      isPrevPossible: !(!props.loop && isFirstSlide),
      leftIndices: leftIndices(visible, props.bias, props.dir, runtime.currentIndex, total),
      rightIndices: rightIndices(visible, props.bias, props.dir, runtime.currentIndex, total),
      hasHiddenSlides,
    }))

    const setSize = () => {
      // console.log('setSize')
    }
    const handleMousedown = (e) => {
      if (!e.touches) {
        // e.preventDefault()
      }
      setRuntime((next) => ({
        ...next,
        mousedown: true,
        dragStartX: ('ontouchstart' in window) ? e.touches[0].clientX : e.clientX,
      }))
    }
    const handleMouseup = () => {
      setRuntime((next) => ({
        ...next,
        mousedown: false,
        dragStartX: 0,
      }))
    }
    const handleMousemove = (e) => {
      if (!runtime.mousedown) {
        return
      }

      const eventPosX = ('ontouchstart' in window) ? e.touches[0].clientX : e.clientX
      if ('ontouchstart' in window) pauseAutoplay()
      
      const deltaX = (runtime.dragStartX - eventPosX)
      setRuntime((next) => ({
        ...next,
        dragOffset: deltaX,
      }))

      if (deltaX > props.minSwipeDistance) {
          handleMouseup()
          goNext()
      } else if (deltaX < -props.minSwipeDistance) {
          handleMouseup()
          goPrev()
      }
    }

    if (isBrowser) {
      window.addEventListener('resize', setSize)
      if ('ontouchstart' in window) {
        theElement.addEventListener('touchstart', handleMousedown, { passive: true })
        theElement.addEventListener('touchend', handleMouseup, { passive: true })
        theElement.addEventListener('touchmove', handleMousemove, { passive: true })
      } else {
        theElement.addEventListener('mousedown', handleMousedown, { passive: true })
        theElement.addEventListener('mouseup', handleMouseup, { passive: true })
        theElement.addEventListener('mousemove', handleMousemove, { passive: true })
      }
      theElement.addEventListener('mouseenter', pauseAutoplay, { passive: true })
      theElement.addEventListener('mouseleave', resumeAutoplay, { passive: true })
    }

    return (() => {
      if (isBrowser) {
        window.removeEventListener('resize', setSize)
        if ('ontouchstart' in window) {
          theElement.removeEventListener('touchstart', handleMousedown, { passive: true })
          theElement.removeEventListener('touchend', handleMouseup, { passive: true })
          theElement.removeEventListener('touchmove', handleMousemove, { passive: true })
        } else {
          theElement.removeEventListener('mousedown', handleMousedown, { passive: true })
          theElement.removeEventListener('mouseup', handleMouseup, { passive: true })
          theElement.removeEventListener('mousemove', handleMousemove, { passive: true })
        }
        theElement.removeEventListener('mouseenter', pauseAutoplay, { passive: true })
        theElement.removeEventListener('mouseleave', resumeAutoplay, { passive: true })
      }
    }
  )
  }, [
    runtime.currentIndex,
    props.border,
    props.boxShadow,
    props.viewport,
    props.width,
    props.height,
    props.dir,
    props.bias,
    goNext,
    goPrev,
    hasHiddenSlides,
    props.children.length,
    props.display,
    props.loop,
    props.minSwipeDistance,
    runtime.dragStartX,
    runtime.mousedown,
    props.autoplay,
    props.autoplayTimeout,
  ])

  const computed = useMemo(() => {
    const slideWidth = getSlideWidth(props.viewport, props.width, props.border, props.boxShadow)
    const slideHeight = getSlideHeight(props.viewport, props.width, props.height, props.border)
    const containerHeight = slideHeight + getBoxShadowSize(props.boxShadow)

    const updatedRuntime = {
      total,
      slideWidth,
      slideHeight,
      isLastSlide,
      isFirstSlide,
      visible,
      isNextPossible: !(!props.loop && isLastSlide),
      isPrevPossible: !(!props.loop && isFirstSlide),
      leftIndices: leftIndices(visible, props.bias, props.dir, runtime.currentIndex, total),
      rightIndices: rightIndices(visible, props.bias, props.dir, runtime.currentIndex, total),
      hasHiddenSlides,
      containerHeight,
    }
    setRuntime((next) => ({
      ...next,
      ...updatedRuntime,
    }))

    return updatedRuntime
  }, [
    runtime.currentIndex,
    hasHiddenSlides,
    isFirstSlide,
    isLastSlide,
    props.bias,
    props.border,
    props.boxShadow,
    props.dir,
    props.height,
    props.loop,
    props.viewport,
    props.width,
    total,
    visible,
  ])

  const dimmensions = { width: `${computed.slideWidth}px`, height: `${computed.slideHeight}px`}

  return (
    <React.Fragment>
      <div className={classes.container} ref={carouselEl} style={{ height: `${computed.containerHeight}px`, margin: 0}}>
        <div ref={slotsEl} className={classes.carouselSlider} style={dimmensions}>
          { 
            React.Children.map(
              props.children, 
              (child, index) => {
                const { children, ...noChildren } = props
                return React.cloneElement(child, { ...noChildren, ...computed, ...runtime, index, goFar, classes })
              }
            )
          }
        </div>
        {props.controlsVisible && <Controls {...runtime} goNext={goNext} goPrev={goPrev} onKeyPressHandler={onKeyPressHandler} />}
      </div>
    </React.Fragment>
  )
}


Carousel.propTypes = {
  count: mixed,
  perspective: mixed,
  display: mixed,
  loop: PropTypes.bool,
  animationSpeed: mixed,
  dir: PropTypes.string,
  width: mixed,
  height: mixed, 
  border: mixed,
  space: mixed,
  startIndex: mixed,
  clickable: PropTypes.bool,
  disable3d: PropTypes.bool,
  minSwipeDistance: PropTypes.number,
  inverseScaling: mixed,
  controlsVisible: PropTypes.bool,
  controlsPrevHtml: PropTypes.string,
  controlsNextHtml: PropTypes.string,
  controlsWidth: mixed,
  controlsHeight: mixed,
  onLastSlide: PropTypes.func,
  onSlideChange: PropTypes.func,
  bias: PropTypes.string,
  onMainSlideClick: PropTypes.func,
  // mixin autoplay
  autoplay: PropTypes.bool,
  autoplayTimeout: mixed,
  autoplayHoverPause: PropTypes.bool,
}

export default Carousel;
