import React, { useCallback, useRef, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '../gatsby-link';


const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  value: {
    textAlign: 'center',
    fontSize: '120px',
    display: 'block',
    fontWeight: 'bold',
    color: '#fff',
    width: '100%',
  },
  text: {
    textAlign: 'center',
    fontSize: '40px',
    display: 'block',
    color: '#fff',
    width: '100%',
    paddingBottom: 24,
    fontWeight: 'bold',
  },
  animatedText: {
    textAlign: 'center',
    fontSize: '40px',
    display: 'block',
    color: '#fff',
    width: '100%',
    paddingBottom: 24,
    animation: `$glow 1s infinite ease`, 
  },
  box: {
    borderRadius: 20,
  },
  link: {
    textDecoration: 'none',
    '&:active': {
      textDecoration: 'none',
      
    },
    '&:focus': {
      textDecoration: 'none',
      
    },
    '&:visited': {
      textDecoration: 'none',

    },
    '&:hover': {
      textDecoration: 'none',

    },
  },
  linked: {
    borderRadius: 20,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fff',
    } 
  },
  "@keyframes glow": {
    from: {
      color: '#fc0',
    },
    to: {
      color: '#fff',
    }
  }
}));

const Counter = props => {
  const { counter, setVisible, index, backgroundColor } = props
  const classes = useStyles();
  const itmeRef = useRef();

  const callbackFunction = useCallback((entries) => {
    const [entry] = entries;
    if (!counter.visible && entry.isIntersecting && itmeRef.current) {
      setVisible(index)(true)
    } else if (counter.visible && !entry.isIntersecting && itmeRef.current) {
      setVisible(index)(false)
    }
  }, [counter.visible, setVisible, index])

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    }
      const observer = new IntersectionObserver(callbackFunction, options)
    if (itmeRef.current) observer.observe(itmeRef.current)
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (itmeRef.current) observer.unobserve(itmeRef.current)
    }

  }, [itmeRef, callbackFunction])

  const cardClass = (counter.ended) ? 'linked' : 'box'
  
  return (

      <Grid item xs={12} md={4} lg={4} xl={4} ref={itmeRef}>
        {!counter.url &&
          <Box className={classes[cardClass]} style={{backgroundColor: backgroundColor}}>
          <div className={classes.value}>{Math.round(counter.value)}</div>
          {!counter.ended && <div className={classes.animatedText}>{counter.label}</div>}
          {counter.ended && <div className={classes.text}>{counter.label}</div>}

        </Box>}
        {counter.url && <Link to={counter.url} className={classes.link}>
        <Box className={classes[cardClass]} style={{backgroundColor: backgroundColor}}>
          <div className={classes.value}>{Math.round(counter.value)}</div>
          {!counter.ended && <div className={classes.animatedText}>{counter.label}</div>}
          {counter.ended && <div className={classes.text}>{counter.label}</div>}

        </Box>
        </Link>}
      </Grid>
  )
}


export default Counter;