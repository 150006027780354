import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const BoardGameImageCard = (props) => {
  const { slide, carousel } = props;
  return (
    <>
    <GatsbyImage
      alt={slide.name || slide.image.title || 'Boardgame'}        
      style={{ margin: 0, height: carousel.height, width: carousel.width }}
      image={slide.image.gatsbyImageData}
      title={slide.name || slide.image.title} 
    />
    </>
  )

}

export default BoardGameImageCard;
